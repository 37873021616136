/* eslint-disable */
import ImageCanvas from './imageLayer.js'
import Canvas from './Canvas.js'
import CanvasController from './canvasController.js'
import { Polygon } from './Shape/Polygon.js'

export default class DrawTool {
  constructor (displayArea, regionCanvas, imageCanvas, canvasLayer, callback, options = {}) {
    this.locked = options.locked === true

    this.$displayArea = displayArea
    this.$regionCanvas = regionCanvas
    this.$imageCanvas = imageCanvas
    this.$canvasLayer = canvasLayer
    this.callback = callback

    this.regionCanvas = new Canvas(this.$regionCanvas.getContext('2d'), options)
    this.imageCanvas = new ImageCanvas(this.$imageCanvas.getContext('2d'))

    this.displayWidth = this.$displayArea.clientWidth
    this.displayHeight = this.$displayArea.clientHeight

    this.canvasController = new CanvasController({
      regionCanvas: this.regionCanvas,
      imageCanvas: this.imageCanvas,
      $region: this.$regionCanvas,
      $image: this.$imageCanvas,
      $canvasLayer: this.$canvasLayer,
      $displayArea: this.$displayArea
    })
    this.initEvent()
  }

  initEvent () {
    const _this = this

    // 滚轮滚动
    this.$displayArea.addEventListener('wheel', function (e) {
      e.preventDefault()
      if (e.altKey || e.ctrlKey || e.shiftKey) return
      _this.canvasController.wheel({
        deltaY: e.deltaY,
        offsetX: e.offsetX,
        offsetY: e.offsetY
      })
    })

    // 鼠标按下
    this.$regionCanvas.addEventListener('mousedown', function (e) {
      if (!e) return
      const btnCode = e.button
      const keyStatus = { // 键盘状态
        ctrl: e.ctrlKey,
        shift: e.shiftKey,
        alt: e.altKey
      }
      switch (btnCode) {
        case 0:
          if (keyStatus.alt) return
          _this.canvasController.regionCanvas.leftMouseDown({
            keyStatus,
            x: e.offsetX,
            y: e.offsetY,
            shape: 'rect',
            // tipText: _this.getCurrentTip(),
            tipText: '',
            lineWidth: null,
            isOrderly: false, // 有序
            scaleSwitch: true // 单点拖拽缩放
          })
          break
        case 2:
          _this.rightMouseDown = true
          break
        default:
        // console.log('Do nothing.')
      }
    })

    // 鼠标移动
    this.$regionCanvas.addEventListener('mousemove', function (e) {
      if (!e) return
      if (_this.locked) {
        return
      }

      const keyStatus = { // 键盘状态
        ctrl: e.ctrlKey,
        shift: e.shiftKey,
        alt: e.altKey
      }

      if (!_this.rightMouseDown) {
        _this.canvasController.regionCanvas.leftMouseMove({
          keyStatus,
          x: e.offsetX,
          y: e.offsetY,
          dx: e.movementX,
          dy: e.movementY
        })
      } else {
        _this.canvasController.rightMouseMove({
          dx: e.movementX,
          dy: e.movementY
        })
      }
    })

    // 鼠标抬起
    this.$regionCanvas.addEventListener('mouseup', function (e) {
      if (!e) return
      const keyStatus = {
        ctrl: e.ctrlKey,
        shift: e.shiftKey,
        alt: e.altKey
      }

      const btnCode = e.button
      switch (btnCode) {
        // 鼠标左键按压
        case 0:
          if (e.altKey) {
            if (_this.locked) {
              return
            }
            // 移除图形
            _this.canvasController.removeShape({
              x: e.offsetX,
              y: e.offsetY
            })
          } else {
            _this.canvasController.regionCanvas.leftMouseUp({
              keyStatus,
              x: e.offsetX,
              y: e.offsetY
            })
          }
          _this.callback()
          break
        // 鼠标右键按压
        case 2:
          _this.rightMouseDown = false
          break
        default:
      }
    })

    // 鼠标右键点击
    this.$regionCanvas.addEventListener('contextmenu', function (e) {
      e.preventDefault()
    })
  }

  clearCanvas () {
    this.canvasController.imageCanvas.clear()
    this.canvasController.regionCanvas.ctx.clearRect(0, 0, this.canvasController.$region.width,
      this.canvasController.$region.height)
    this.canvasController.regionCanvas.drawingShapeObject = null
    this.canvasController.regionCanvas.shapeList = []
    this.canvasController.regionCanvas.shapeListName = []
    this.canvasController.regionCanvas.typeList = []
    this.canvasController.imageCanvas.ctx.clearRect(0, 0, this.canvasController.$image.width,
      this.canvasController.$image.height)
  }

  clearCanvasV2 () {
    this.canvasController.regionCanvas.shapeList = []
    this.canvasController.regionCanvas.ctx.clearRect(0, 0, this.canvasController.$region.width,
      this.canvasController.$region.height)
  }

  loadImage (src) {
    return this.canvasController.loadImage({
      src,
      displayWidth: this.displayWidth,
      displayHeight: this.displayHeight
    })
  }

  /**
   * 绘制已有选区
   * @returns {Array} list
   */
  drawSharp (list) {
    if (list) {
      list.forEach((item, index) => {
        const position = item.coordinate
        const props = {
          ctx: this.regionCanvas.ctx,
          isOrderly: false, // 无序，不需要同步更新其他选区tipText
          scaleSwitch: true,
          shape: 'polygon',

          isActive: item.active === true,
          tipText: item.label,
          $data: item.data,

          points: [
            { x: position.x_min, y: position.y_min }, // 左上
            { x: position.x_min, y: position.y_max }, // 左下
            { x: position.x_max, y: position.y_max }, // 右下
            { x: position.x_max, y: position.y_min }, // 右上
          ]
        }
        const section = new Polygon(props)
        section.scale(this.canvasController.scaleOrigin * this.canvasController.currentScale)
        this.regionCanvas.addShape(section, props.shape)
      })

      this.canvasController.rectToPoly()
      this.regionCanvas.leftMouseMove({})
    }
  }

  pick () {
    this.canvasController.rectToPoly()
    return this.canvasController.pack()
  }

  getCurrentTip () {
    return this.pick().length + 1
  }

  render () {
    setTimeout(() => {
      this.regionCanvas.leftMouseMove({})
    }, 80)
  }
}
