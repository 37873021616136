<template>
  <div ref="viewport" class="SectionPicker">
    <div ref="container" class="SectionPicker-container">
      <canvas ref="image"></canvas>
      <canvas ref="region"></canvas>
    </div>
  </div>
</template>

<script>
import DrawTool from './DrawTool'

export default {
  name: 'SectionPicker',
  props: {
    // 是否锁定已提交题目的标记
    // 1. 锁定后不能拖拽 - drawTool.locked
    // 2. 锁定后可切换激活选区 - callback->activeShape
    lock: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    lock: {
      immediate: true,
      handler (val) {
        if (this.drawTool) {
          this.drawTool.locked = val
        }
      }
    }
  },
  data () {
    return {
      drawTool: null
    }
  },
  methods: {
    /**
     * 初始化标记工具
     */
    init () {
      const vm = this
      const callback = () => {
        const datalist = drawTool.pick()
        this.$emit('change', datalist ? datalist.filter(item => item.isActive) : [])
      }
      const drawTool = new DrawTool(
        this.$refs.viewport,
        this.$refs.region,
        this.$refs.image,
        this.$refs.container,
        callback,
        {
          /**
           * 返回当前选中区域
           */
          pickerShape: (shape, stage) => {
            // if (shape.strokeStyle === 'red') {
            //   return true
            // }
            // stage.shapeList.forEach(m => {
            //   if (m.strokeStyle === 'red') {
            //     m.strokeStyle = 'blue'
            //   }
            // })
            // shape.strokeStyle = 'red'
            // drawTool.render()
            // this.$emit('pick', shape)

            if (shape.$data.ocr) {
              return true
            }
            stage.shapeList.forEach(m => {
              if (m.$data.ocr) {
                m.$data.ocr = false
                m.strokeStyle = 'blue'
              }
            })
            shape.$data.ocr = true
            shape.strokeStyle = 'red'
            drawTool.render()
            this.$emit('pick', shape)
          },

          /**
           * 点击激活已禁用选框
           * @return {Boolean} 是否允许穿透 (针对重叠标注框，默认不穿透)
           */
          activeShape: (shape, stage) => {
            console.log('--- activeShape', shape, stage)
            if (!vm.selectable) return true // 不响应点击操作
            stage.shapeList.forEach(item => {
              item.setActive(shape.$data.id === item.$data.id)
            })
            drawTool.render()
          },

          /**
           * Draw前置操作
           * @return {Boolean} 是否执行新增选框操作
           */
          beforeDraw: (stage, props) => {
            console.log('--- beforeDraw', stage, props)
            return true
          },

          /**
           * Draw后置操作
           * @return {Boolean} 是否执行新增选框操作
           */
          afterDraw: (stage, shape) => {
            console.log('--- afterDraw', stage, shape)
            this.$emit('draw', shape)
            return true
          },
        }
      )
      drawTool.locked = this.lock
      this.drawTool = drawTool
      return this
    },

    pageRender (pageId, pageImg, sections) {
      const drawTool = this.drawTool
      if (!this.drawTool) {
        return console.error('<SectionPicker> 未初始化')
      }
      const drawSharp = () => drawTool.drawSharp(sections)
      drawTool.clearCanvas()
      return drawTool.loadImage(pageImg)
        .then(drawSharp)
        .catch((err) => {
          console.error('加载失败,请刷新页面重新操作', err)
          throw err
        })
    },

    // 锁定所有选框
    disableAllShape () {
      if (this.drawTool) {
        this.drawTool.regionCanvas.shapeList.forEach(shape => shape.setActive(false))
        this.drawTool.render()
      }
    }
  },
  mounted () {
    this.init()
    // window.drawTool = this.drawTool
  }
}
</script>

<style lang="scss" scoped>
$ScrollbarSize: 8px;

.SectionPicker {
  position: relative;
  background: #F6F6F6;
  border: 1px solid #EEE;
  box-sizing: border-box;
  margin: 0;
  z-index: 1;
  overflow: scroll;

  // 滚动条样式
  &::-webkit-scrollbar{
    width: $ScrollbarSize;
    height: $ScrollbarSize;
  }
  // 滚动条内部滑块样式
  &::-webkit-scrollbar-thumb{
    border-radius: $ScrollbarSize;
    -webkit-box-shadow:inset 0 0 $ScrollbarSize rgba(0,0,0,.3);
    background-color:#F8F8F8;
  }
}
.SectionPicker-container {
  position: absolute;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 3px #CCC;
}
.SectionPicker-container > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
